import {
  isValidElement,
  ReactChild,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from "react";
import { TeliaIconProps } from "@telia/b2x-telia-icon/types/types";

export type TabContentProps = {
  active?: boolean;
  className?: string;
  "data-testid"?: string;
  icon?: TeliaIconProps["name"];
  name: string;
  tabId: string;
  tabPath?: string;
};

export type TabContentPropsWithChildren = TabContentProps & { children: ReactNode };

export const isTabContent = (
  child:
    | ReactElement
    | ReactChild
    | ReactPortal
    | ReactFragment
    | string
    | number
    | boolean
    | null
    | undefined
): child is ReactElement<TabContentProps> =>
  !!child && isValidElement<TabContentProps>(child) && !!child.props.name && !!child.props.tabId;
