import React from "react";
import classnames from "classnames";

import styles from "./b2x-telia-tab-content.module.scss";
import { TabContentPropsWithChildren } from "./b2x-telia-tab-content.utils";

export const TeliaTabContent = ({
  children,
  tabId,
  "data-testid": dataTestid,
  className,
  active,
}: TabContentPropsWithChildren) => {
  const classes = classnames(className, {
    [styles["telia-tab-content"]]: true,
    [styles["telia-tab-content--selected"]]: active,
  });
  return (
    <div
      aria-hidden={!active}
      aria-labelledby={tabId + "-header"}
      className={classes}
      data-testid={dataTestid + "-content"}
      hidden={!active}
      id={tabId + "-content"}
      role="tabpanel"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export * from "./b2x-telia-tab-content.utils";
